body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.auth-logo {
  width: 175px;
  height: 100px;
  max-height: 100% !important;
  object-fit: contain;
}
.category:hover{
  box-shadow: 0px 0px 8px 4px #f5d0d0;
}
.vertical{

}

/* COMMON */

.custom-btn-primary {
  background: #e94a63;
  border: 1px solid #e94a63;
}

.custom-btn-primary:hover {
  background: #e94043;
  border: 1px solid #e94043;
}

.custom-btn-primary:active {
  background: #e94043 !important;
  border: 1px solid #e94043 !important;
}

.cutom-text-primary {
  color: #e94a63 !important;
}

a.cutom-text-primary:hover {
  color: #e94043 !important;
}

.custom-bg-primary {
  background-color: #e94a63;
}

.logo-text-color {
  color: #e94a63 !important;
  margin-top: 20px !important;
}

.header-bg-custom {
  background: linear-gradient(to right, #e94a63, #eb9379);
}

h3.custom-page-title {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
}

.nav-active-text-primary .nav>li.active>a {
  color: #e94a63 !important;
}

.c-padding {
  padding: 1.5rem 0px 1px 0px;
}

.c-btn-download {
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  background: #fff;
  border-color: #fff;
  color: #e94b64;
}

.c-toggle-btn {
  margin-top: 35px;
}

.c-toggle-btn .toggle-title {
  padding-right: 20px;
}

.c-toggle-btn .indigo {
  background-color: #e9636c;
  color: #fff;
}

.form-control:not(.is-valid):not(.is-invalid):focus {
  border-color: #ea7f74;
}

.form-control:focus {
  color: inherit;
  // box-shadow: 0 0 0 0.2rem rgb(232 76 100 / .25);
}

.custom-file-input:focus~.custom-file-label {
  border-color: #c4daff;
  // box-shadow: 0 0 0 0.2rem rgb(232 76 100 / .25);
}

.c-pb-0 {
  padding-bottom: 0 !important;
}

.pagination .active .page-link,
.pagination .active a {
  color: #fff !important;
  background-color: #e84c64 !important;
  border-color: #e84c64 !important;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  // box-shadow: 0 0 0 0.2rem rgb(232 76 100 / .25);
}

.action-icon-trash {
  background-color: #e94a63;
}

/* BRANCH PAGE */
.status-badge {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 15px;
}

.status-badge.inactive-badge {
  color: #f21378;
  background-color: #fde1ee;
      width: 70px;
  /* margin-bottom: 12px; */
  margin-top: -23px;
  /*margin-left: 12px;*/
}

.status-badge.active-badge {
  color: #27a05a;
  background-color: #def6e8;
      width: 70px;
  /* margin-bottom: 12px; */
  margin-top: -23px;
  /*margin-left: 12px;*/
}
.status-badge.waiting-badge {
  color: #fff;
  background-color: #f4c414;
}
.status-badge.confirm-badge {
  color: #fff;
  background-color: #27a05a;
}
.action-icon-shield{
  background-color: #4CAF50;
}

.service-for-tag {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 15px;
}

.service-for-tag.man-tag {
  color: #3e455a;
  background-color: #e3e5e9;
}

.service-for-tag.woman-tag {
  color: #cba20a;
  background-color: #fdf6d9;
}

.service-for-tag.unisex-tag {
  color: #1094b5;
  background-color: #d9f4fb;
}

.service-for-tag.services-list-tag {
  color: #116bff;
  background-color: #e1ecff;
}

.table-filter-sec {
  margin-bottom: 30px;
}

.table-search-title {
  width: 50px;
  display: inline-block;
}

.table-search-sec {
  display: inline-block;
  width: calc(100% - 60px);
  margin-left: 5px;
}

/* BRANCH DETAIL */

.branch-detail-card {
  background: #fff;
  border-width: 0;
  border-radius: .25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
  margin-bottom: 1.5rem;
  min-height: 100px;
  max-height: 300px;
  display: flex;
}

.branch-img-sec {
  width: 375px;
  flex: 0 0 375px;
  max-height: 275px;
}

img.branch-img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.branch-detail-contentsec {
  padding: 20px;
}

.c-branch-tab .nav-item {
  position: relative;
  border: inherit;
  /* width: calc(25% - 25px); */
  flex: 1 1 auto;
  text-align: center;
  margin-right: 25px;
}

.c-branch-tab .nav-item:last-child {
  margin-right: 0;
  /* width: 25%; */
}

.c-branch-tab .nav-item .nav-link {
  transition: all .15s ease;
  color: #e94a63;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.c-branch-tab .nav-item .nav-link.active {
  color: #fff;
  background-color: #e94a63;
}
.split{

}
.list-group {
  padding: 10px 6px;
}
.agf{
padding: 6px 10px 0px 14px;

}
.agd{
    padding:6px 10px 0px 40px;
}
.border-line{
  border-top: 1px solid #9E9E9E;
  
}
.card-body{
padding: 10px;
}
.badge-success {
      color: #1aae6f;
  background-color: rgba(147, 231, 195, .5);
}
.text-pad{
  padding: 15px 63px !important;
}
.badge {
border-radius: .375rem;
  }
.txt-algn{
vertical-align: top!important;
}   
.action-icon-plus-circle{
  background-color: #ea6c6d;
}
.radio{
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  
}
.radio input[type="radio"]{
  display: none;
}
.radio span{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border:3px solid #e95767;
  display: block;
  cursor: pointer;


}
.radio span:after{
  content: "";
  height: 8px;
  width: 8px;
  background: #ea5e69;
  display: block;
  transform: translate(-50%,-50%) scale(0);
  border-radius: 50%;
  transition: 300ms ease-in-out 0s;
  position: relative;
  left: 7px;
  top: 7px;

}
.radio input[type="radio"]:checked ~ span:after{
  transform: translate(-50%,-50%) scale(1);
}
.indigo {
  background-color: #e95466;
  color: #fff;
}
.card-pad{
  padding-left: 0px;
  padding-right: 0px;
}
.card-body a{
  color: #000000d9;
}
.card-body a :hover{
   color: #000000d9;
}   

.nav-icon 
{
  svg
  {
    width: 16px !important;
    height: 16px !important;
  }
}


.required
{
  color: red;
}

.chip
{
  background-color: #e94a63 !important;
}

.css-26l3qy-menu
{
  z-index: 9999 !important;
}

.react-bootstrap-table-pagination 
{
  .btn-secondary
  {
    background-color: #e84c64 !important;
    border-color: #e84c64 !important;
  }
  .react-bootstrap-table-pagination-total
  {
    color: #99a0ac!important;
    font-size: .825rem !important;
  }
}

.services-edit-link
{
  color: #e84c64 !important;
}

.service-list
{
  padding: 20px;
  .single-service
  {
    .col-8
    {
      font-weight: bold;
    }
  }
}


#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  text-align: center;
  border: 1px solid #d3d3d3;
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}

.active-service
{
  span 
  {
    color: green;
    font-size: 20px;
    line-height: 1.1em;
  }
}

@media only screen and (max-width: 767px)
{
  .react-bootstrap-table table
  {
    table-layout: unset !important;
  }
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

.gallary
{
  width: 100%;
  .file-input
  {
    padding: 3%;
  }
  .upload-container
  {
    min-height: 180px;
    border: 2px dotted #e84c64;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  .gallary-listings
  {
    width: 100%;
    padding: 10px;
    .title
    {
      font-size: 18px;
    }
    .single 
    {
      padding: 5px;
      img 
      {
        height: auto;
        width: auto;
        max-width: 500px;
        display: inline-block;
        position: relative;
      }
    }
    .single {
      position: relative;
      margin-top: 50px;
      width: 500px;
      height: 300px;
    }
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: background 0.5s ease;
    }
    
    .single:hover .overlay {
      display: block;
      background: rgba(0, 0, 0, .3);
    }
    
    img {
      position: absolute;
      width: 500px;
      height: 300px;
      left: 0;
    }
    
    .button {
      position: absolute;
      width: 500px;
      left:0;
      top: 180px;
      text-align: center;
      opacity: 0;
      transition: opacity .35s ease;
    }
    
    .button a {
      width: 200px;
      padding: 12px 48px;
      text-align: center;
      color: white;
      border: solid 2px #e84c64;
      background-color: #e84c64;
      z-index: 1;
    }
    
    .single:hover .button {
      opacity: 1;
    }
    
  }
}

.settings-gallary
{
  .link-color
  {
    border: 1px solid #e84c64;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
  }
  .description, .autocomplete
  {
    width: 100%;
  }

  .description
  {
    margin-top: 10px;
  }

  .file-input
  {
    padding: 3%;
  }
  .upload-container
  {
    min-height: 180px;
    border: 2px dotted #e84c64;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  .gallary-listings
  {
    width: 100%;
    padding: 10px;
    .title
    {
      font-size: 18px;
    }
    .single 
    {
      padding: 10px;
      border-bottom: 1px solid #ddd;
      div:first-child
      {
        padding-left: 5px !important;
      }
      label 
      {
        width: 100%;
        font-weight: bold;
      }
      img 
      {
        height: auto;
        width: auto;
        max-width: 450px;
      }
    }
    .services-edit-link
    {
      border: 1px solid #e84c64;
      border-radius: 10px;
      padding: 5px 10px;
    }
    .disabled
    {
      color: rgb(255, 208, 0);
    }
    .enabled
    {
      color: green;
    }
  }
}

.float-right
{
  float: right;
  text-align: right;
}

.no-padding
{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-margin-left
{
  margin-left: 0px !important;
}

.no-margin-right
{
  margin-right: 0px !important;
}

.no-padding-left
{
  padding-left: 0px !important;
}

.no-padding-right
{
  padding-right: 0px !important;
}